@font-face {
    font-family: "Gotham Book";
    src: url("../resources/fonts/Gotham Book.otf") format("opentype");
}

@font-face {
    font-family: "Gotham Bold";
    src: url("../resources/fonts/Gotham Bold.otf") format("opentype");
}
@font-face {
    font-family: "Gotham Light";
    src: url("../resources/fonts/Gotham-Light.otf") format("opentype");
}
@font-face {
    font-family: "Gotham Thin";
    src: url("../resources/fonts/Gotham-Thin.otf") format("opentype");
}
@font-face {
    font-family: "Gotham XLight";
    src: url("../resources/fonts/Gotham-XLight.otf") format("opentype");
}

html {
    font-size: 10px;
    font-size: 62.5%;
}

body {
    font-size: 1.05em;
    padding: 0;
    margin: 0;
    font-family: "Gotham Book"
}

a {
    text-decoration: none;
}

a, h1,h2,h3,h4,h5,h6 {
    letter-spacing: 0.075em;
    line-height: 1.5;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0.5em 0;
}

.btn {
    border-radius: 0.25em;
}

.btn-jumbo {
    min-width: 8em;
    font-size: 1.25em;
    padding: 1em 4em;
}
.stati div{
    width: calc(100% - 3.5em);
    display: block;
    float:right;
    text-align:right;
  }
  .stati div b {
    font-size:2.2em;
    width: 100%;
    padding-top:0px;
    margin-top:-0.2em;
    margin-bottom:-0.2em;
    display: block;
  }
  .stati div span {
    font-size:1em;
    width: 100%;
    color: rgb(0, 0, 0,0.8); 
    display: block;
  }
  
  .sidebartitles{
    text-transform: capitalize;
  }
  /* input:checked {
   background-color: white !important;
  } */

  /* #ac_path_3a{
    display:none;
}
#ac_path_3b{
    display:none;
}
#ac_path_3c{
    display:none;
}
#ac_path_3d{
    display:none;
}
#ac_path_3e{
    display:none;
}
#ac_path_3f{
    display:none;
}
#ac_path_3g{
    display:none;
}
#ac_path_3h{
    display: none;
}
#ac_path_3i{
    display:none;
}
#ac_path_3j{
    display: none;
}
#ac_path_3k{
  display: none;

}
#ac_path_3l{
    display: none;
}
#ac_path_3m{
  display: none;

}
#ac_path_3m{
    display: none;
}
#ac_path_3n{
  display: none;

}
  #ac_path_3o{
      display: none;
  }
  #ac_path_3p{
    display: none;
}
#ac_path_3r{
    display: none;
}
#ac_path_3s{
  display: none;

}

  #ac_path_3t{
      display: none;
  }
  #ac_path_3u{
    display: none;
  
  }
  #ac_path_3v{
      display: none;
  }
  #ac_path_3w{
    display: none;
}
  
#ac_path_3x{
display: none;
}
#ac_path_3y{
    display: none;
    }
    #ac_path_3z{
        display: none;
        } */
  .logo-size{
    width: 121.69px;
    height: 50.6px;
  }
a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
}
.gmnoprint div {
    /* background:none !important; */
    border-radius: 20px !important;
    /* background-color: whit !important; */
    
}
.gm-control-active img{
    src:url("../statics/images/ic-plus.svg") !important
}
.gm-fullscreen-control{
    display: none !important;

}
#tableChart1{
    height: 800px;
    background: #333;
    border-radius: 5px;
    padding: 10px;

}
.anychart-credits {
    display: none;
 }
 #tableChart2{
    height: 800px;
    background: #333;
    border-radius: 5px;
    padding: 10px;
}
#tableChart3{
    /* height: 200px; */
    background: #333;
    border-radius: 5px;
    padding: 10px;

}
#tableChart4{
    /* height: 300px; */
    background: #333;
    border-radius: 5px;
    padding: 10px;

}
#tableChart5{
    height: 200px;
    background: #333;
    border-radius: 5px;
    padding: 10px;

}

.gm-style-mtc >button{
    background-color: #545454  !important;
    color: white !important;
  }

  .gm-style-mtc >button >ul {
   display: none !important;
  }
  /* label{
   display: none !important;


  } */
  /* span >img{
   display: none !important;
  } */
  ul{
      display: none  !important;
  }

  .map-scrol{
    margin-top:"30px";
    overflow-y: "scroll"
  }