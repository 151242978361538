html {
    background-color: initial;
}

#root {
    font-family: "Gotham Book", Tahoma, Geneva, sans-serif !important;
    background-color: #222222;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
}




b,strong,h1,h2,h3,h4,h5,h6,
.bold, .site-header {
    font-family: "Gotham xlight", Tahoma, Geneva, sans-serif;
    font-weight: 300;
}

.theme-default,
.theme-light,
[data-theme="default"],
[data-theme="light"] {
    background-color: #f2f2f2 !important;
    color: #222 !important;
}

.theme-invert,
.theme-dark,
[data-theme="dark"] {
    background-color: #434444 !important;
    color: #f2f2f2 !important;
}

.theme-white,
[data-theme="white"] {
    background-color: #FFF !important;
    color: #000 !important;
}


.theme-black,
[data-theme="invert"],
[data-theme="black"] {
    background-color: #000 !important;
    color: #FFF !important;
}

.theme-transparent,
[data-theme="transparent"] {
    background: transparent !important;
    background-color: transparent !important;
    color: inherit !important;
}

.theme-lighter,
.theme-lighter-1,
[data-theme="lighter-1"] {
    background-color: rgba(255, 255, 255, 0.1) !important;
}

.theme-lighter-2,
[data-theme="lighter-2"] {
    background-color: rgba(255, 255, 255, 0.2) !important;
}

.theme-lighter-3,
[data-theme="lighter"],
[data-theme="lighter-3"] {
    background-color: rgba(255, 255, 255, 0.3) !important;
}

.theme-lighter-4,
[data-theme="lighter-4"] {
    background-color: rgba(255, 255, 255, 0.4) !important;
}

.theme-lighter-5,
[data-theme="lighter-5"] {
    background-color: rgba(255, 255, 255, 0.5) !important;
}

.theme-lighter-6,
[data-theme="lighter-6"] {
    background-color: rgba(255, 255, 255, 0.6) !important;
}

.theme-lighter-7,
[data-theme="lighter-7"] {
    background-color: rgba(255, 255, 255, 0.7) !important;
}

.theme-lighter-8,
[data-theme="lighter-8"] {
    background-color: rgba(255, 255, 255, 0.8) !important;
}

.theme-lightest,
.theme-lighter-9,
[data-theme="lightest"],
[data-theme="lighter-9"] {
    background-color: rgba(255, 255, 255, 0.9) !important;
}


.theme-darker,
.theme-darker-1,
[data-theme="darker"], 
[data-theme="darker-1"] {
    background-color: rgba(0, 0, 0, 0.05) !important;
}

.theme-darker-2,
[data-theme="darker-2"] {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.theme-darker-3,
[data-theme="darker-3"] {
    background-color: rgba(0, 0, 0, 0.25) !important;
}

.theme-darker-4,
[data-theme="darker-4"] {
    background-color: rgba(0, 0, 0, 0.4) !important;
}

.theme-darker-5,
[data-theme="darker-5"] {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.theme-darker-6,
[data-theme="darker-6"] {
    background-color: rgba(0, 0, 0, 0.6) !important;
}

.theme-darker-7,
[data-theme="darker-7"] {
    background-color: rgba(0, 0, 0, 0.7) !important;
}

.theme-darker-8,
[data-theme="darker-8"] {
    background-color: rgba(0, 0, 0, 0.8) !important;
}

.theme-darkest,
.theme-darker-9,
[data-theme="darkest"],
[data-theme="darker-9"] {
    background-color: rgba(0, 0, 0, 0.9) !important;
}

.color-0 {
    color: #000 !important;
}

.color-1 {
    color: #e33622 !important;
}

.color-3 {
    color: #888 !important;
}

.color-4 {
    color: #434444 !important;
}

.color-9 {
    color: #FFF !important;
}

.bg-color-1 {
    background-color: white !important;
}

.bg-color-2 {
    background-color: white !important;
}

.bg-color-3 {
    background-color: #434444 !important;
}

.bg-color-4 {
    background-color: white !important;
}

.font-1 {
    font-family: 'Open Sans',arial,sans-serif;
}

.link-btn,
.link-btn-round.active {
    background-color: #222;
    color: #fff;
}

.link-btn-round,
.link-btn.inactive {
    background-color: #f2f2f2;
    color: #222;
}

.link {
    color: #FFF;  
}

.frame {
    background-image: url("/resources/images/background-image.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #222;
}

.frame-body-left {
    /*background-color: rgba(255, 255, 255, 0.1);*/
}

.frame-header {
    background-color: rgba(0, 0, 0, 0.1);
}

.frame-footer {

}

.loading-indicator {
    color: #e33622;
    background-color: #FFF;
}

.btn-primary {
    background-color: #e33622;
    border-color: #e33622;
    color: #FFF;
}

button:hover,
a:hover {
    filter: brightness(120%) !important;
}

.toggle-menu-btn {
    color: white;
}

a.active,
.menu-item.active {
    background: #222222;
    border-left:5px solid #E33622;
    ;
}

.card {
    /*background-color: #f8f8f8;*/
}

.box {
    /*background-color: #f8f8f8;*/
}

.page ul {
    list-style: none;
}
.page ul li {
}

.page ul li::before {
    background-image: url('/cached/iTrazo_Bullet_Red.png');
    background-size: contain;
    background-repeat: no-repeat;
}


.site-header ul {
    background-color: rgba(255, 255, 255, 0.95);
}

@media screen and (min-width: 1200px) {
    .site-header ul {
        background-color: transparent;
    }

}

.site-header ul li a:after {
    background: #e33622;
}


.link {
    color: #FFF;
}


.link:hover {
    text-decoration: none;
    color: #FFF;
}
.logincard{
    background-color: #191919;
    color: white;
    padding: 30px;
    border-radius: 10px;
   }
.my-input{
    background: #383838;
    color:#FFF
}