
.DateRangePickerInput.DateRangePickerInput_1, 
.DateRangePickerInput__withBorder, 
.DateRangePickerInput__withBorder_2{
background-color: rgb(88, 88, 88);;
} 
.DateRangePicker{
    width: 300px;
}
.DateInput_input{
  background: #222222; 
  color: white;
  border: 0px; 
}

.CalendarDay__selected {
  background: #222222 !important;
  color: white;
  border: 0px;
  border-radius: 5px 0px 0px 0px;
}
.CalendarDay__selected_end_3{
    border-radius: 0px 0px 10px 0px !important;
}

.CalendarDay__selected:hover {
  background: #222222 !important;
  color: white;
}
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background:#333333 !important;
}
.DateRangePickerInput_arrow_svg {
    vertical-align: middle;
    fill: #FFFFFF;
    height: 24px;
    width: 24px;
}
.CalendarDay__selected_span{
  background:#333333 !important;
  border:0px
}
.CalendarDay__selected_span:hover{
    background:#333333;
    border:0px

  }
  .CalendarMonth{
      background-color: #545454;
  }
  .CalendarDay__default{
    background-color: #545454;
    color: white;
    border: 0px;
  }
  .CalendarDay__default:hover{
    background-color: #222222;
    color: white;
    border: 0px;
  }
  
  .DayPickerNavigation_button__verticalDefault{
      background-color: #545454 !important;
      color: white;
      border: 0px;
  }
  .DayPickerNavigation_button__default:focus, 
  .DayPickerNavigation_button__default:hover{
      border: 0px !important;
  }
  .DayPickerNavigation_svg__vertical{
      fill: white;
  }
  .DayPickerKeyboardShortcuts_show__topRight{
      display: none;
  }
  .DateInput_fangShape{
    fill: #545454;
    border: 0px !important;
  }
  .DateInput_fangStroke{
      stroke: #545454;
  }
  .DateRangePicker_picker{
    background-color: #545454 !important;
  }
  .navbar a {
    float: left;
    font-size: 16px;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
  
  .dropdown {
    float: left;
    overflow: hidden;
    ;
  }
  
  .dropdown .dropbtn {
    font-size: 16px;  
    border: none;
    outline: none;
    padding: 14px 16px;
    width:200px;
    background-color: #222222;
    font-family: inherit;
    margin: 0;
    font-family: Gotham book;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 13px;
    text-transform: uppercase;
    color: #FFFFFF;
    opacity: 0.6;
  }
  
  .navbar a:hover, .dropbtn {
    background-color: #545454;
  }
  
  .display-none {
    display: none;
    position: absolute;
    background-color: #545454;
    min-width: 160px;
    width: 260px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .display-none a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  .dropdown .display-none {
    width: 200px;
  }
  /* .dropbtn[onclick] .display-none{
    display: block;
    width: 200px;
  } */
  .display-none{
    display: none;
  }
  .display{
    width: 200px;
    display: block;
    position: absolute;
    background-color: #545454;
    border-radius: 5px;
    min-width: 160px;
    width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  .CalendarMonth{
    padding: 5px !important;
  }
  .CalendarMonthGrid__horizontal {
    left:0px !important 
  }
  .DayPickerNavigation_rightButton__horizontalDefault{
    right:20px !important 
  }
  .CalendarMonthGrid{
    background-color: #545454;
    width:287px !important;
    height: 260px !important;
  }
  .DayPicker_transitionContainer__horizontal{
    width: 287px !important;
    height: 310px;
  }
  .DayPicker_weekHeader{
    padding: 0px !important;
    top: 50px !important;
  }
  .DayPicker__withBorder{
    width: 287px !important;
  }
  .DayPicker_focusRegion{
    width: 287px !important;
    background-color: #545454;
  }
  .DayPickerKeyboardShortcuts_show,
  .DayPickerKeyboardShortcuts_showSpan__bottomRight{
    display: none;
  }
  .DayPickerNavigation_svg__horizontal{
    fill:#fff;
  }
  .DayPickerNavigation_button__default{
    background: none !important;
    border: 0px;
  }
  .DateInput_input{
    font-family: Gotham bold;
    line-height: 11px;
    text-align: center;
    font-size: 12px;
    
  }
  .CalendarDay__default{
    width: 39px;
    height: 38px;
    font-size: 12px;
    font-family: 'Gotham Bold';
    line-height: 11.48px;
    text-align: center;
  }
  .CalendarMonth_caption{
    font-size: 12px;
    font-family: 'Gotham Bold';
    line-height: 11.48px;
    text-align: center;

  }
  .DayPicker_weekHeader_li{
    font-size: 12px;
    line-height: 11.48px;
    font-family: 'Gotham Bold';

  }